import { orderBy } from "lodash";
import { useCallback, useState, useEffect } from "preact/hooks";

const getLiftedBunkerProductsInPort = (liftedBunkers, bunkersList) => {
  const ids = liftedBunkers?.map((x) => x.id);
  let bunkers = [];

  if (ids?.length) {
    bunkers = [
      ...(liftedBunkers.concat(
        bunkersList?.filter((x) => !ids.includes(x.id))
      ) ?? []),
    ];
  }
  return orderBy(bunkers, "type");
};

export const usePortcallCardState = (portcall) => {
  const getInitialPortCallState = useCallback(() => {
    const liftedBunkerProductsInPort = getLiftedBunkerProductsInPort(
      portcall.liftedBunkerProductsInPort,
      portcall.bunkerProducts
    );

    return {
      ...portcall,
      liftedBunkerProductsInPort,
      bunkersTaken: portcall.liftedBunkerProductsInPort?.some(
        (x) =>
          x.quantity || x.vesselQuantity || x.barge?.quantity || x.barge?.name
      ),
    };
  }, [portcall]);

  const [portCallInitial, setPortCallInitial] = useState(
    getInitialPortCallState()
  );

  const [portCallState, setPortCallState] = useState(portCallInitial);
  const setEta = useCallback(
    (newEta) => {
      setPortCallState({ ...portCallState, eta: newEta });
    },
    [portCallState, setPortCallState]
  );

  const setEtaToShow = useCallback((newEta) => {
    setPortCallState((prevState) => {
      const reportingEtaUpdated =
        Date.parse(newEta) <
        Date.parse(prevState.portCallEtaResult.earliestLaycanEnd);
      const updatingDate = new Date().toISOString();
      return {
        ...prevState,
        eta: newEta,
        portCallEtaResult: {
          ...prevState.portCallEtaResult,
          etaToShow: newEta,
          actualEta: newEta,
          reportingEta: reportingEtaUpdated
            ? newEta
            : prevState.portCallEtaResult.reportingEta,
          reportingEtaUpdatedAt: reportingEtaUpdated
            ? updatingDate
            : prevState.portCallEtaResult.reportingEtaUpdatedAt,
          actualEtaUpdatedAt: updatingDate,
        },
      };
    });
  }, []);

  const setEtd = useCallback(
    (newEtd) => {
      setPortCallState({ ...portCallState, etd: newEtd, isChangedETD: true });
    },
    [portCallState, setPortCallState]
  );
  const setStartOfSeaPassage = useCallback(
    (newStartOfSeaPassage) => {
      setPortCallState({
        ...portCallState,
        startOfSeaPassage: newStartOfSeaPassage,
      });
    },
    [portCallState, setPortCallState]
  );
  const setEndOfSeaPassage = useCallback(
    (newEndOfSeaPassage) => {
      setPortCallState({
        ...portCallState,
        endOfSeaPassage: newEndOfSeaPassage,
      });
    },
    [portCallState, setPortCallState]
  );
  const setDepartureDraft = useCallback(
    (fieldName, newDraft) => {
      setPortCallState({
        ...portCallState,
        departureDraft: {
          ...portCallState.departureDraft,
          [fieldName]: newDraft,
        },
      });
    },
    [portCallState, setPortCallState]
  );
  const setArrivalDraft = useCallback(
    (fieldName, newDraft) => {
      setPortCallState({
        ...portCallState,
        arrivalDraft: {
          ...portCallState.arrivalDraft,
          [fieldName]: newDraft,
        },
      });
    },
    [portCallState, setPortCallState]
  );

  const setArrivalTugs = useCallback(
    (newTugs) => {
      setPortCallState({ ...portCallState, arrivalTugs: newTugs });
    },
    [portCallState, setPortCallState]
  );

  const setDepartureTugs = useCallback(
    (newTugs) => {
      setPortCallState({ ...portCallState, departureTugs: newTugs });
    },
    [portCallState, setPortCallState]
  );

  const setBunkersFuel = useCallback(
    (index, newValue, isArrival) => {
      const newBunkers = isArrival
        ? [...portCallState.bunkersRemainingOnBoardOnArrival]
        : [...portCallState.bunkersRemainingOnBoardOnDeparture];
      const updatedBunkers = newBunkers.map((newBunker, newBunkerIndex) =>
        newBunkerIndex === index
          ? { ...newBunker, quantity: newValue }
          : newBunker
      );
      if (isArrival) {
        setPortCallState({
          ...portCallState,
          bunkersRemainingOnBoardOnArrival: updatedBunkers,
        });
      } else {
        setPortCallState({
          ...portCallState,
          bunkersRemainingOnBoardOnDeparture: updatedBunkers,
        });
      }
    },
    [portCallState, setPortCallState]
  );

  const setFreshWater = useCallback(
    (newValue, isArrival) => {
      if (isArrival) {
        setPortCallState({
          ...portCallState,
          freshWaterOnArrival: newValue,
        });
      } else {
        setPortCallState({
          ...portCallState,
          freshWaterOnDeparture: newValue,
        });
      }
    },
    [portCallState, setPortCallState]
  );

  const setRemarks = useCallback(
    (newRemarks) => {
      setPortCallState({ ...portCallState, remarks: newRemarks });
    },
    [portCallState, setPortCallState]
  );

  const setBillOfLadingStatus = useCallback(
    (cargoIdArg, newBillOfLadingStatus) => {
      const newCargoes = [
        ...portCallState.cargoes.map((cargo) => ({ ...cargo })),
      ];
      const cargoIndex = newCargoes.findIndex(
        ({ portCallCargoId }) => cargoIdArg === portCallCargoId
      );
      newCargoes[cargoIndex].billOfLadingStatus = parseInt(
        newBillOfLadingStatus
      );
      setPortCallState({ ...portCallState, cargoes: newCargoes });
    },
    [portCallState, setPortCallState]
  );

  const setEarlyDelayArrivalReason = useCallback(
    (newReasons) => {
      setPortCallState({
        ...portCallState,
        earlyDelayArrival: {
          ...portCallState.earlyDelayArrival,
          reasons: newReasons.map((x) => x.value),
        },
      });
    },
    [portCallState, setPortCallState]
  );

  const setEarlyDelayArrivalComment = useCallback(
    (newComment) => {
      setPortCallState({
        ...portCallState,
        earlyDelayArrival: {
          ...portCallState.earlyDelayArrival,
          comment: newComment,
        },
      });
    },
    [portCallState, setPortCallState]
  );

  const setVesselLastUpdated = useCallback(() => {
    setPortCallState({ ...portCallState, vesselChangedAt: Date.now() });
  }, [portCallState, setPortCallState]);

  const resetPortCallState = useCallback(
    (portcall) => {
      setPortCallState(portcall);
    },
    [setPortCallState]
  );

  const setFieldValue = useCallback((fieldName, value) => {
    setPortCallState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  }, []);

  const setLiftedBunkerProductField = useCallback(
    (bunkerType, fieldName, value) => {
      const updateValue = (prev) => {
        const newValue = {
          ...prev,
          liftedBunkerProductsInPort: prev.liftedBunkerProductsInPort.map(
            (singlePrev) =>
              singlePrev.type === bunkerType
                ? { ...singlePrev, [fieldName]: value }
                : singlePrev
          ),
        };

        return {
          ...newValue,
        };
      };
      setPortCallState((prev) => updateValue(prev));
    },
    []
  );

  const setLiftedBunkerProductBargeField = useCallback(
    (bunkerType, fieldName, value) => {
      setPortCallState((prev) => ({
        ...prev,
        liftedBunkerProductsInPort: prev.liftedBunkerProductsInPort.map(
          (singlePrev) =>
            singlePrev.type === bunkerType
              ? {
                  ...singlePrev,
                  barge: { ...singlePrev.barge, [fieldName]: value },
                }
              : singlePrev
        ),
      }));
    },
    []
  );

  return {
    portCallState,
    setEta,
    setStartOfSeaPassage,
    setDepartureDraft,
    setArrivalDraft,
    setRemarks,
    setBillOfLadingStatus,
    resetPortCallState,
    setVesselLastUpdated,
    setEndOfSeaPassage,
    setBunkersFuel,
    setFreshWater,
    setEarlyDelayArrivalReason,
    setEarlyDelayArrivalComment,
    setEtd,
    setFieldValue,
    setLiftedBunkerProductField,
    setLiftedBunkerProductBargeField,
    portCallInitial,
    setEtaToShow,
    setPortCallInitial,
    setDepartureTugs,
    setArrivalTugs,
  };
};
